.admin{
    min-height: 100vh;
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    flex: auto;
    background: #f0f2f5;


    &-body{
        overflow-x: hidden;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex: auto;
        flex: auto;
        background: #f0f2f5;
        min-height: 0;
    }
}
.load-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    z-index: 214;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading {
    width: 100px;
    height: 100px;
    background-color: #3672ab;
    border-radius: 12px;
    animation: loading 2s linear infinite;
}

@keyframes loading {
	0% { transform: rotateX(0deg) rotateY(0deg); }

	50% { transform: rotateX(0deg) rotateY(180deg); }

	100% { transform: rotateX(180deg) rotateY(180deg); }
}
