button,input[type=submit]{
    outline: none;
    border:none;
    padding: .75rem 1.2rem;
    font-size: 1.2rem;
    cursor: pointer;
}
.btn{
    &,
    &:link,
    &:visited {
        text-transform: capitalize;
        text-decoration: none;
        padding: .75rem 1.2rem;
        display: inline-block;
        transition: all .2s;
        position: relative;
        font-size: 1.2rem;

        //Change for the <button> element
        border: none;
        cursor: pointer;
    }
    &.right-icon{
        display: flex;
        align-items: center;
        max-height: 4rem;
        svg,i{
            font-size:2rem;
            margin-left: .5rem;
        }
    }
    
    
    &:active,
    &:focus {
        outline: none;
        // transform: translateY(-1px);
        // box-shadow: 0 .5rem 1rem rgba(#222,.2);
    }
    &--primary {
        background-color: $color-primary;
        color: $color-white;

        &::after {
            background-color: $color-primary;
        }
    }
    &--danger {
        background-color: $color-red;
        color: $color-white;

        &::after {
            background-color: $color-red;
        }
    }
    &-primary{
        background:#{$color-primary-light};
        color:#fff;

        &:hover{
            background: lighten($color-primary-light,7);
        }
    }

    &-info{
        background:#{$color-primary};
        color:#fff;

        &:hover{
            background: lighten($color-primary,7);
        }
    }
    &-danger{
        background:#{$color-red};
        color:#fff;

        &:hover{
            background: lighten($color-red,7);
        }
    }
    &-success{
        background:#{$color-green};
        color:#fff;

        &:hover{
            background: lighten($color-green,7);
        }
    }
    // &-submit{
    //     text-align: center;
    //     width: 50%;
    //     margin:1rem auto;
    //     background-color: #999;
    //     color:#fafafa;
    // }
    &-submit{
        color:#fff;
        background: #{$color-blue};
        margin-top: 1rem;
        // border: 1px solid #646464;
        box-shadow: 0 2px 3px rgba(0,0,0,0.12);
        padding: 1.25rem 0;
        width: 100%;
        font-size: 1.4rem;
    }
}

.btn{
    &-rounded{
        &,
        &:link,
        &:visited {
            text-transform: capitalize;
            text-decoration: none;
            padding: .75rem 2rem;
            display: inline-block;
            transition: all .2s;
            position: relative;
            font-size: 1.2rem;
            background: lighten($color-grey,20);
            //Change for the <button> element
            border: none;
            border-radius: 12rem;
            cursor: pointer;
            max-height: 3rem;
            color:#fff;
            margin:0 5px;

            &.left-icon{
                display: flex;
                align-items: center;
                max-height: 4rem;
                svg,i{
                    font-size:2rem;
                    margin-right: .5rem;
                }
            }
            &.right-icon{
                display: flex;
                align-items: center;
                max-height: 4rem;
                svg,i{
                    font-size:2rem;
                    margin-left: .5rem;
                }
            }

            
        }
        
        
        &:active,
        &:focus {
            outline: none;
            // transform: translateY(-1px);
            // box-shadow: 0 .5rem 1rem rgba(#222,.2);
        }
        &.with-icon{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 1.5rem;
            height: 3rem;
            
            svg,i{

                font-size: 20px;
                margin-right: 5px;
            }
        }
        &.info{
            background:#{$color-primary};
            color:#fff;

            &:hover{
                background: lighten($color-primary,7);
            }
        }
        &.danger{
            background:#{$color-red};
            color:#fff;

            &:hover{
                background: lighten($color-red,7);
            }
        }
        &.success{
            background:#{$color-green};
            color:#fff;

            &:hover{
                background: lighten($color-green,7);
            }
        }
    }

    
}


.import-csv{
    width: 150px;
    height: 3rem;
    position: relative;
    label{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #{$color-blue};
        // border-radius: 12rem;
        color:#fff;
        font-size: 1.4rem;
        cursor: pointer;
        
        &:hover{
            background: lighten($color-blue,8);
        }
    }

    input[type="file"]{
        width:100%;
        height:100%;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
    }

    // input[type="file"]::-webkit-file-upload-button{
    //     background: #{$color-green};
    //     outline: none;
    //     border: none;
    //     color: #fff;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     padding: 10px;
    //     border-radius: 12rem;

    //  }

    // &:after{
    //     content:'Import';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }
}



.button{
    &-group{
        padding:10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        &__right{
            justify-content: flex-end;
        }
    }
}


.lds-hourglass {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 0px;
    box-sizing: border-box;
    border: 10px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-hourglass 1.2s infinite;
  }
  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
  
button:disabled{
    &,&:active{
        background-color: #cfcfcf;
        color:#666;
    }
}