.treks-list .trek-grid {
    display: grid;
    grid-template-columns: 25% 15% 40% 10% 10%;
}
.treks{
    grid-template-columns:68% 30%;
}
.trek-item__name{
    a{
        cursor: pointer;
    }
}

.team{
    &-details{
        display: grid;
        grid-template-columns: 240px calc(100% - 240px);
    }
    &-image{
        .rs-avatar{
            width: 100px;
            height: 100px;
            &-image{
                width: 100% !important;
                height: 100% !important;;
                object-fit: cover;
            }
        }
    }
    &-designation{
        font-size: 16px;
        color: #565656;
    }
    &-name{
        display: flex;
        align-items: flex-end;
        h2{
            font-size: 25px;
            line-height: 1.3;
            margin-right: 10px;
        }
        span{
            font-size: 18px;
            color: #999;
        }
    }
    &-detail{
        font-size: 14px;
        color: #767676;
        padding: 15px 0;
        line-height: 1.8;
    }
}