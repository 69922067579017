.status-form{
    padding: 0;
    .rs-form{
        flex-direction: column;
        align-items: flex-start;

        &-group{
            margin-bottom: 5px !important;
        }
        .rs-btn-toolbar{
            margin-top: 15px;
        }
    }
}
.status-list {
    padding: 0;
}
.room-icon{
    display: flex;
    flex-direction: row;
    align-items: center;

    img{
        width: 22px;
        height: 22px;
        margin-right: 15px;

    }
}

.room-list{
    flex-direction: column;

    .status-list__item{
        margin-bottom: 10px;
        padding: 8px 15px;
    }
}

.room{
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #24aeae;
    // max-width: 60%;
    max-width: 100%;

    .type{
        padding-bottom:6px ;
        border-bottom: 1px solid #fff;
        margin-bottom: 8px;
        display: inline-block;
        font-weight: 600;
        font-size: 15px;
        color: #fff;
        text-decoration: none;
    }
    .heading{
        margin-bottom: 5px;
    }

    .desc{
        color: #fff;
    }
    .rooms-icon{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // padding: 10px 0;

        &__item{
            display: flex;
            flex-direction: column;
            align-items: center;
            // margin-right: 15px;
            margin:10px 10px 10px 0;
            background: rgba(#0f5858,.5);
            padding: 4px 15px;
            border-radius: 4px;

            img{
                width: 20px;
                height: 20px;
                object-fit: cover;
            }
        }
    }
    .update-icons{
        position: absolute;
        top: 15px;
        right: 15px;
        display: flex;
        flex-direction: row;

        i{
            font-size: 15px;
        }

        .icon-edit{
            margin-right: 10px;
        }
    }
}


.single{
    margin: 15px 0;
    padding: 10px;

    &-upload{
        width: 100%;
        border: 1px solid #dfdfdf;
        border-radius: 4px;
        background: #fff;

        &-title{
            font-size: 15px;
            font-weight: 600;
            color:#565656
        }

        &-header{
            width: 100%;
            padding: 5px 20px;
            border-bottom: 1px solid #dfdfdf;

            h3{
                font-size: 16px;
                color: #636363;
                font-weight: 600;
                line-height: 2;
            }
        }
        &-body{
            padding: 5px 20px;
        }
        &-bottom{
            padding: 10px 20px;
            border-top: 1px solid #dfdfdf; 
        }
        &-footer{
            padding: 10px 20px;
            border-top: 1px solid #dfdfdf;
        }
    }
    &-slider{
        padding: 10px 20px;

        .slick-arrow{
            display: none !important;
        }
    }
    &-room{
        width: 100%;
        display: flex;
        flex-direction: column;
        background: #fff;
        padding: 20px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        margin-bottom: 25px;
        border-radius: 4px;

        &__type{
            h1{
                font-size: 22px;
                font-weight: 600;
                line-height: 1.4;
            }
        }
        &__heading{
            font-size: 16px;
            color:#444;
        }
        &__desc{
            font-size: 14px;
        }
        &__icons{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 15px 0 0;
            flex-wrap: wrap;
        }
        &__icon{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 15px;
            background-color: #007da6;
            padding: 10px 15px 10px 15px;
            border-radius: 4px;
            margin-bottom: 15px;
            .icon-img{
                width: 25px;
                height: 25px;
                margin-right: 5px;
                img{
                    width: 100%;
                    height: 100%;
                    filter: invert(1);
                }
            }
            .icon-label{
                font-size: 14px;
                color:#fff;
            }
        }
    }
}

.atimages{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    &-block{
        width: 66px;
        height: 66px;
        margin-top: 10px;
        margin-right: 10px;
        position: relative;
        border-radius: 6px;

        &:hover{
            .atimages-link{
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &-image{
        width: 100%;
        height: 100%;
        border: 1px solid #cfcfcf;
        border-radius: 6px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &-link{
        color: #8e8e93;
        cursor: pointer;
        position: absolute;
        top: 3px;
        right: 3px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 50%;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: 0.2s opacity;
        transition: 0.2s opacity;
        z-index: 4;
    }
}

.slider-image{
    max-width: 80%;
    max-height: 100%;
    object-fit: cover;
    margin: 0 auto;
}

.slide-image{
    width: 100%;
    height: 70vh;
}
.custom-icon{
    width: 30px !important;
    height: 30px !important;
    &:before{
        font-size: 30px !important;
        color:#24aeae !important;
    }
}