*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

    @include respond(tab-land) { // width < 1200?
        font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
    }

    @include respond(tab-port) { // width < 900?
        font-size: 50%; //1 rem = 8px, 8/16 = 50%
    }
    
    @include respond(big-desktop) {
        font-size: 75%; //1rem = 12, 12/16
    }
}

body {
    box-sizing: border-box;

    @include respond(tab-port) {
        padding: 0;
    }
    font-family: 'Nunito Sans', 'Roboto',  'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

// ::selection {
//     background-color: $color-primary;
//     color: $color-white;
// }

h1,
h2,
h3,
h4,
h5,
h6{
    font-family: $font-stack;
}

a{
    text-decoration: none;
    color:#222;
    
}

.rs-table-pagination-toolbar{
    background: #fff !important; 
    padding: 10px 15px !important;
}

.error-message{
    color:lighten($color-red,12);
}

ul,li{
    list-style: none;
}