@import '../../scss/abstracts/variables';
@import '../../scss/abstracts/mixins';

.settings{
    padding: 20px;

    &-email{
        padding: 15px;
        background: #f3f9ff;
        margin: 15px 0;
        border-radius: 4px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12);
        form{
            display: flex;
            flex-direction: row;
            align-items: center;

            .rs-form-group{
                margin-right: 10px;
            }
        }
        .status-list{
            padding: 0;
        }
    }
}
.code{
    width: 100%;
    display: flex;
    flex-direction: row;

    &-type{
        width: 150px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border:1px solid #cfcfcf;
        background-color: #dfdfdf;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
        margin-right: 20px;
        color:#1e2e2e;
        border-radius: 4px;
        &:hover{
            background-color: #d1d1d1;
        }

        &.active-code{
            background-color: #{$color-primary-light-3};
            border-color: #{$color-primary-light-2};
            font-weight: 600;

            &:hover{
                background-color:darken($color-primary-light-3,10);
            }
        }
    }
}


.settings{
    &-upload{
        width: 100%;
        padding:15px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.2);
        background-color: #fff;
        border-radius: 4px;
        &-form{
            display: flex;
            align-items: flex-end;

            .rs-btn{
                margin-top: 10px;
            }
        }
        &-image{
            display: flex;
            position: relative;
            .error-message{
                position: absolute;
                top: 106%;
                z-index: 111;
                background: #fff;
                border-radius: 6px;
                width: 100px;
                padding: 3px;
                font-size: 12px;
                box-shadow: 0 2px 4px rgba(0,0,0,0.3);
                color: #f44336;
            }

            & + div{
                padding: 0 15px;
            }
        }
        &-container{
            width: 100%;
            margin-top: 20px;
        }
        &-list{
            width: 100%;
            display: flex;
            flex-direction: row;
            list-style: none;
            flex-wrap: wrap;

            &__item{
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px;
                margin-right: 15px;
                background: #e4e4e4;
                margin-bottom: 15px;
                border-radius: 5px;
                box-shadow: 0 1px 3px rgba(0,0,0,0.12);

                img{
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                }
                span{
                    font-size: 16px;
                    color:#343434;
                    font-weight: 500;
                }
                .close{
                    font-size: 20px;
                    cursor: pointer;
                    color: #cdcdcd;
                    margin-left: 15px;
                    line-height: 0;

                    &:hover{
                        color:#{$color-red}
                    }
                }
            }
        }
    }
}

.floating{
    position: fixed;
    bottom: 30px;
    right: 30px;
    padding: 8px 16px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    border-radius: 4px;
    background-color: #24aeae;
    color: #fff;
}

.settings{
    &-no{
        padding: 20px;
        font-size: 25px;
        color: #999;
    }
}