.danger{
    font-size: 1.5rem;
    color:lighten($color-red,15);
    padding: 1rem;
    // text-align: left;
}


// .primary-heading{
//     font-size: 2rem;
//     padding:10px 0;
//     font-weight: 400;
//     color:#{$color-primary-light};
// }

.secondary-heading{
    font-size:3.5rem;
    padding:10px 0;
    margin-bottom: 10px;
    font-weight: 700;
    color:#fff;
    position: relative;

    &:after{
        content: "";
        position: absolute;
        left:0%;
        // top:100%;
        width:10rem;
        bottom:2px;
        height:3px;
        border-radius: 10px;
        background-color: #fff;
    }
}

.primary-heading{
    font-size:3rem;
    padding:5px 0;
    margin-bottom: 10px;
    font-weight: 700;
    color:#444;
    position: relative;
    display: inline-block;

    &:after{
        content: "";
        position: absolute;
        left:0%;
        // top:100%;
        width:100%;
        bottom:2px;
        height:3px;
        border-radius: 10px;
        background-color: #{$color-primary-light};
    }
}

.tertiary-heading{
    font-size:3rem;
    padding:5px 0;
    margin-bottom: 10px;
    font-weight: 700;
    color:#444;
    position: relative;
    display: inline-block;

    &:after{
        content: "";
        position: absolute;
        left:50%;
        // top:100%;
        width:50%;
        bottom:2px;
        height:3px;
        border-radius: 10px;
        background-color: #{$color-primary-light};
        transform:translateX(-50%)
    }
}

.text-para{
    margin:1rem 0;
    font-size: 1.5rem;
    color:#fafafa;
}

.text-link{
    cursor: pointer;
    color:#0167b1;
    text-align: left;

    &:hover{
        color: #4098D7;
    }
}