.trek{
    width: 100%;

    &-container{
        width: 90%;
        // margin: 2rem auto;
        margin: 2rem 2rem;
    }
    &-block{
        width: 100%;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        box-shadow: 0 3px 5px rgba(0,0,0,0.2);
        &__button{
            padding: 10px 0;

            button{
                background-color: #24AEAE;
                color:#fff;
                border-radius: 3px;
                &:hover,&:visited,&:active,&:focus{
                    background-color: darken(#24AEAE, 10);
                    color:#fafafa;
                }
                i{
                    font-size: 12px;
                    margin-right: 5px;
                }
            }
        }
        &__header{
            width: 100%;
            padding: 15px 20px;
            background-color: #dfdfdf;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            h2{
                font-size: 22px;
                color:#464646;
                font-weight: 500;
                margin-right: 10px;
                line-height: 1;
            }

            span{
                font-size: 17px;
                color:#888;
                line-height: 1;
            }
        }
        &__chips{
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: #f1f1f1;
            padding: 10px 20px;
        }
        &__chip{
            width: 100%;
            margin-bottom: 10px;
            
            // &:not(:first-child){
            //     margin-top: 10px;
            // }

            h4{
                font-size: 17px;
                font-weight: 400;
                margin-bottom: 5px;
            }
            .chips{
                margin-right: 10px;
            }
        }
        &__body{
            padding: 15px;
            .image-grid{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

            }
            .image-item{
                width: 100%;
                max-width: 200px;
                max-height: 150px;
                img{
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        &__slider{
            width: 100%;
            // margin-bottom: 10px;
            .no-images{
                padding: 10px;
                background: #efefef;
                width: 100%;
                display: block;
            }
        }
        &__tabs{
            width: 100%;
            background-color: #f6f6f6;
            display: flex;
            flex-direction: column;
            box-shadow: 0 2px 4px rgba(0,0,0,0.15);
            &-tab{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                background-color: #e4e4e4;
                border-bottom: 2px solid #cdcdcd;
                .trek-tab{
                    width: 120px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 40px;
                    font-size: 16px;
                    color:#464646;
                    cursor: pointer;

                    &:hover{
                        color:#888;
                    }

                    &.active{
                        position: relative;
                        // border-bottom: 2px solid #43ADDB;
                        &:after{
                            content:'';
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 100%;
                            height: 2px;
                            background-color: #43ADDB;
                        }
                    }
                }
            }
            &-body{
                padding: 10px;
            }
        }
    }
}


.pricing{
    .price_table{
        width: 100%!important;
        border-bottom: none;
        border-radius: 4px;
        // box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
        td,th{
            padding: 15px 5px;
            display: table-cell;
        }
        thead{
            // box-shadow: 0 3px 6px rgba(0,0,0,.2);
            tr{
                background-color: #333!important;
            }
            td{
                color: #fafafa;
                background-color: initial;
                border-radius: unset;
                font-weight: 600;
                font-size: 18px;
            }
        }
        tbody{
            tr{
                border-bottom: 1px solid rgba(0,0,0,.12);
                // td{
                //     color: #fafafa!important;
                //     background-color: initial;
                //     border-radius: unset!important;
                // }
            }
            td{
                color: #444!important;
                font-weight: 500;
                margin-right: 3rem;
                font-size: 16px;

                h2{
                    font-size: 20px;
                    font-weight: 600;
                }

                &:first-child {
                    border-right: 1px solid #dcdcdc;
                }

                .price{
                    font-size: 14px!important;
                    color: #fff;
                    background-color: #646464;
                    padding: 0 12px;
                    width: 90%;
                    height: 30px;
                    margin: 8px auto;
                    border-radius: 2rem;
                    font-weight: 500;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            tr{
                &:first-child{
                    
                    td{

                        ul{
                            margin-left: 20px;
                            list-style: none;
                            li{
                                font-weight: 500!important;
                                position: relative;
                                line-height: 30px;
                                &:before{
                                    content: "arrow_forward";
                                    display: block;
                                    font-family: Material Icons;
                                    height: 1.5rem;
                                    font-weight: 700;
                                    width: 1.5rem;
                                    font-size: 1.5rem;
                                    display: inline-block;
                                    position: absolute;
                                    top: 0;
                                    left: -1.75rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.includes{
    h2{
        font-size:17px;
        font-weight: 600;
        color: #d48619;
    }
}
.include,.exclude{
    padding-left: 30px;
    list-style: none;

    &__list{
        position: relative;
        line-height: 30px;
        color: #555;
        font-size: 15px;
        margin-bottom: 5px;

        &::before{
            content: "check";
            display: block;
            font-family: Material Icons;
            height: 20px;
            font-weight: 700;
            width: 20px;
            color: #00b300;
            font-size: 20px;
            position: absolute;
            top: 2px;
            left: -30px;
        }
    }
}

.exclude__list{
    &::before{
        content: "close";
        color: #c00;
        top:1px;
    }
}

.itinerary{
    width: 100%;
    border: 1px solid #cfcfcf;
    border-radius: 12px;
    margin-bottom: 20px;
    &-show{
        .itinerary-container{
            border-bottom: 1px solid #cfcfcf;
        }
    }
    &-container{
        padding: 12px;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 12px;
        cursor: pointer;
    }
    &-title{
        font-size: 16px;
        font-weight: 600;
        flex:1;
    }
    &-actions{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap:16px;
    }
    &-day{
        font-size: 16px;
        font-weight: 600;
    }
    &-details{
        padding: 16px 12px;
        overflow: hidden;
        img{
            max-width: 100%;
            max-height: 300px;
            object-fit: cover;
        }
    }

}

.pricing{
    &-single{
        padding: 12px 16px;
        border: 1px solid #cfcfcf;
        border-radius: 12px;
    }
    &-content{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
    &-title{
        font-size: 16px;
        font-weight: 600;
    }
    &-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 6px;

        div{
            &:last-child{
                display: flex;
        flex-direction: row;
        align-items: center;
        gap: 6px;
            }
        }
    }
    &-accom{
        h5{
            font-size: 14px;

        }
        &-item{
            padding: 4px 8px;
            border-radius: 12px;
            background-color: #9b53fa;
            color: #fff;
        }
    }
    &-includes{
        display: flex;
        flex-direction: row;
        gap: 6px;
        margin-bottom: 12px;
    }
}

.group-size{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    &:not(:last-child){
        padding-bottom: 12px;
        border-bottom: 1px solid #cfcfcf;
    }

    &-details{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }
    &-buttons{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }
    &__title{
        font-size: 15px;
        color: #ff3197;
        font-weight: 600;
    }
}

.includes{
    margin: 12px 0 24px;

    &-main{
        font-size: 18px;
        font-weight: 500;
        color: #222;
    }
    &-content{

    }
    &__edit,&__delete{
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: #0F5858;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        i{
            font-size: 14px;
            color: #fff;
        }
    }
    &-container{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #cfcfcf;
        padding: 6px 0;

        div{
            &:last-child{
                display: flex;
                flex-direction: row;
                align-items: 
                center;
                gap: 8px;
                
            }
        }
    }
    &-title{
        font-size: 14px;
        font-weight: 400;
    }
}