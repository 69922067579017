.status{
    &-form{
        padding: 20px;

        .rs-form{
            display: flex;
            flex-direction: row;
            align-items: center;

            &-group{
                margin-right: 10px;
            }
        }
    }
    &-list{
        display: flex;
        padding:20px;
        flex-direction: row;
        // margin-top: 20px;
        &__item{
            padding:4px 10px;
            display: inline-block;
            padding: 4px 10px;
            background: #333;
            color: #fff;
            border-radius: 4px;
            margin-right: 10px;

            .name{
                font-size: 15px;
                margin-right: 10px;
            }
            .password{
                border-left: 1px solid #fff;
                padding-left: 10px;
            }
            i{
                font-size: 13px;
                cursor: pointer;
                margin-left: 5px;
                &:hover{
                    color:#dfdfdf;
                }
            }
        }
    }
}


.rs-picker-menu {
    z-index: 11111111 !important;
}