.tab{
    display:flex;
    flex-direction:row;
    width:50%;
    margin:0 auto;
    border:2px solid #646464;
    border-radius: 10px;

    &-item{
        flex:1;
        padding:1rem 1.5rem;
        background-color: #efefef;
        text-align: center;
        cursor: pointer;
        &:not(:last-child){
            border-right: 1px solid #646464;
        }
        &:first-child{
            border-top-left-radius:7px;
            border-bottom-left-radius:7px;
        }
        &:last-child{
            border-top-right-radius:7px;
            border-bottom-right-radius:7px;
        }
        span{
            font-size: 1.5rem;
            color:#646464;
        }
        &.active{
            background-color: #{$color-primary-light};
            span{
                color: #fff;
            }
        }

    }
}


.tabs{
    width: 100%;

    &-list{
        width: 100%;
        display: flex;
        list-style: none;
        // background: #404040;
        background: #{$color-primary-light};
        padding: 0px 25px;
        position: relative;
        z-index: 1;

        &__item{
            font-size: 1.4rem;
            color: #efefef;
            padding: 10px;
            width: 90px;
            margin: 0 5px;
            /* background: #333; */
            text-align: center;
            cursor: pointer;
            height: 4rem;
            transition: all .3s;

            &.tab-active{
                // border-bottom: 3px solid #d4d4d4;
                // background: #555555;
                font-weight: 600;
                color:#{$color-grey};
                // color:#{$color-primary-dark};

                &:nth-child(1){
                    & ~ .active-back{
                        left:25px;
                    }
                }
                &:nth-child(2){
                    & ~ .active-back{
                        left:125px;
                    }
                }
                &:nth-child(3){
                    & ~ .active-back{
                        left:225px;
                    }
                }
                &:nth-child(4){
                    & ~ .active-back{
                        left:325px;
                    }
                }
                &:nth-child(5){
                    & ~ .active-back{
                        left:425px;
                    }
                }
                &:nth-child(6){
                    & ~ .active-back{
                        left:525px;
                    }
                }
                &:nth-child(7){
                    & ~ .active-back{
                        left:625px;
                    }
                }
            }

            
        }
        .active-back{
            position: absolute;
            top: 0;
            left:25px;
            width: 100px;
            height: 4rem;
            background: #{$color-primary-light-2};
            border-bottom: 3px solid #d4d4d4;
            z-index: -1;
            transition: all .3s;
        }

    }

    &-content{
        padding:5px 25px 20px;
        width: 100%;
    }
}