.login{
    &-left{
        @include flexCenter;
        padding:1rem 2rem;
        text-align: left;
        background-image: linear-gradient(to right bottom, #{$color-2},#{$color-3});
        color:#fff;

        &__content{
            width: 75%;
        }
    }
    &-right{
        @include flexCenter;
        background-image: linear-gradient(to left top, #fff,#fafafa);
    }
    &-form{
        width:80%;
        text-align: center;

        form{
            width: 100%;
            margin:3rem 0;
        }
    }
    &-button{
        &,
        &:active,
        &:focus{
            text-transform: capitalize;
            border-radius: 12rem;
            // font-size: 1.75rem !important;
            font-size: 18px !important;
            border:none;
            outline:none;
            background-color: darken($color-primary-light,10);
            color:#fff !important;
            // padding:1rem 2rem;
            // height: 4rem;
            // margin:1rem;
            font-weight: 500;
            width: 175px;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 1rem auto;
            justify-content: center;
            height: 38px;

            &:hover{
                background: #{$color-primary-light};
            }

            span{
                line-height: 0;
            }
        }
    }
}

.forgot-password-link{
    display: block;
    text-align: left;
    width: 60%;
    margin: 0px auto 15px;
    font-size: 15px;
}

.forgot{
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 40% 60%;

    &-top-logo{
        position: absolute;
        top: 20px;
        left:20px;
        width: 100%;

        img{
            max-width: 80%;
            margin: 0px auto;
        }
    }
    &-left{
        position: relative;
        background-image: linear-gradient(to right bottom, #{$color-2},#{$color-3});
    }
    &-right{
        position: relative;
        @include flexCenter;
        flex-direction: column;
        form{
            text-align: center;
        }
    }
    &-status{
        font-size: 17px;
        color:#434343;
        margin:10px 0;
    }
}