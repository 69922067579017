@import '../../scss/abstracts/variables';
@import '../../scss/abstracts/mixins';

.adventures{
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    // grid-template-columns: repeat(2,1fr);
    padding: 10px;
    grid-gap: 15px;

    &-list{
        width: 100%; 

        &__block{
            padding: 15px;
            background: #fff;
            display: flex;
            flex-direction: column;
            box-shadow: 0 1px 2px rgba(0,0,0,0.12);
            border-radius: 2px;
        }

        &__header{
            width: 100%;
            padding: 8px 0;
            border-bottom: 1px solid #dfdfdf;
            font-size: 15px;
            font-weight: 600;
        }
        .adventure-grid{
            display: grid;
            grid-template-columns: 20% 10% 10% 40% 10% 10%; 
        }
        .adventure-item{
            padding: 10px 0 ;
            font-size: 14px;
            color:#444;
            border-bottom: 1px solid #dfdfdf;

            &__name{
                font-size: 16px;
                font-weight: 500;
                a{
                    color:#0F5858;

                    &:hover{
                        color:lighten(#0F5858, 10);
                        text-decoration: none;
                    }

                }
            }
            &__summary{
                max-height: 100px;
                overflow-y: hidden;
            }
            &__edit{
                width: 100%;
                color: #24AEAE;
                font-size: 20px;
                cursor: pointer;

                &:hover{
                    color:lighten(#24AEAE,10)
                }
            }
            &__delete{
                // color:#{$color-red};
                color:rgba(174, 36, 36, 1);
                font-size: 20px;
                cursor: pointer;

                &:hover{
                    color:lighten(rgba(174, 36, 36, 1),10)
                }
            }
        }
    }
    &-form{
        background-color: #fff;
        padding: 20px;
    }
}

.ck-form{
    padding: 10px 0 ;

    label{
        font-size: 15px;
        display: block;
        margin-bottom: 6px;
    }
}