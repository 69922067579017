
//Colors
// $color-primary:#186FAF;
$color-primary:#0167b1;
$color-primary-light:#4098D7;
$color-primary-light-2:#84C5F4;
$color-primary-light-3:#B6E0FE;
$color-primary-dark:#0A558C;
$color-primary-dark-2:#003E6B;
$color-primary-dark-3:#001529;
$color-white:#F0F4F8;
$color-black:#1a1a1a;

$color-1:#ffee36;
$color-2:#9b53fa;
$color-3:#62ffff;
$color-4:#ff3197;
$color-5:#aceb56;

$color-grey:#334E68;
$color-grey-dark:#243B53;
$color-grey-light:#627D98;
$color-grey-light-2:#BCCCDC;

$color-red:#BA2525;
$color-blue:#1890ff;
$color-yellow:#E9B949;
$color-green:#17B897;

//Fonts
$default-fontSize:1.25rem;
$font-stack: 'Nunito Sans', 'Roboto', Helvetica, sans-serif;
$font-nunito: 'Nunito Sans', sans-serif;
$font-roboto: 'Roboto', sans-serif;


$grey-background: #f5f5f5;
$grey-border: #ddd;
$grey-text: #898989;
$grey-dark: #444;
$main-light: #d9f2fb;
$main: #00a9e0;
$main-dark: #007da6;
$success: #7fd81e;
$error: #ff5100;
$warning: #f6c223;
$body: #081f2c;
