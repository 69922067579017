.users{
    margin: 3rem 3rem 0 3rem ;

    &-upload{
        // display: flex;
        // flex-direction: column;
        // .rs-uploader {
        //     padding: 10px 0 ;
        // }
        button{
            width: 80px;
            display: block;
            margin: 10px 0;
        }
    }

    &-heading{
        width: 100%;
        display: block;
        text-align: center;
    }
    &-table{
        width: 100%;
        border:1px solid #efefef;
        border-collapse: collapse;
        thead{
            background: #{$color-primary};
            border: none;
            color:#efefef;

            th{
                padding: 10px 5px;
                font-weight: 600;
                font-size: 1.3rem;
                text-align: left;
                border-bottom: 1px solid #{$color-primary-light};
            }

        }

        tbody{
            background: #dedede;

            td{
                padding:7px 5px;
                font-size: 1.2rem;
                color:#434343;
                border-bottom: 1px solid #bcbcbc;

                .delete{
                    font-size: 2rem;
                    color:#{$color-grey-dark};
                    cursor: pointer;

                    &:hover{
                        color:#{$color-red};
                    }
                }
                

                .table{
                    &-image{
                        width: 50px;
                        height:50px;
    
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        
                        }
                        &.avatar{
                            border-radius: 50%;
                            // border:2px solid #{$color-yellow};
    
                            img{
                                border-radius: 50%;
    
                            }
                        }
                    }
                    &-link{
                        color:#{$color-primary};
                        font-size: 1.3rem;
                        cursor: pointer;
                    }

                }
                
            }
        }
    }
    &-form{
        width: 100%;
        &__heading{
            padding:10px;
            background: #{$color-primary-light-2};
            color:#{$color-primary-dark-2};
        }

        form{
            padding:20px 0;
        }

    }
}

.form{
    &-row{
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;


    }
    &-col-6{
        width: calc(50% - 20px );
        margin-right: 10px;

    }
    &-group{
        width: 100%;
        display: block;
        padding: 4px 0;

        &.error{
            .form-group__label{
                color:#{$color-red};
            }
            .form-group__inp{
                border-color:#{$color-red};
            }

            .error-text{
                color:#{$color-red};
                font-size: 12px;
                display: block;
            }
        }

        &__label{
            font-size: 1.3rem;
            color:#646464;
            font-weight: 500;
        }

        &__inp{
            border:1px solid #d3d3d3;
            outline: none;
            width: 100%;
            padding:7px 10px;
            border-radius: 3px;
            background: #fff;;
        
        }
        &__sel{
            border:1px solid #d3d3d3;
            outline: none;
            width: 100%;
            padding:7px 10px;
            border-radius: 3px;
            background: #fff;;
        
        }
    }
    
    &-submit{
        width: 70%;
        padding: 10px 0;
    }
}
.error-text{
    display: none;
}


.user{
    padding: 20px;
    width: 100%;

    &-block{
        width: 100%;
        padding:10px;
        background: rgba(255,255,255,.9);

    }
    &-top{
        width: 100%;
        display: flex;;
        flex-direction: row;
    }

    &-image{
        width: 130px;
        height: 150px;
        background: #e5e5e5;
        border:1px solid #c4c4c4;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;;
        }
        .upload-image{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            div{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .img-preview{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    img{
                        width: 100px;
                        height: 100px;
                        // margin-bottom:10px;
                    }
                    a{
                        width: 100px;
                        text-align: center;
                    }
                }
            }
        }
    }
    &-info{
        flex-grow: 1;
        padding:0 10px;

        &__title{
            color: #333;
            font-size: 1.3rem;
            margin-right: 10px;
            
        }
        &__data{
            color: #777;
            font-size: 1.3rem;

            .two-inputs{
                display: inline-block;
                input{
                    width: 100px;
                }
            }

            input,select{
                width: 200px;
                border: none;
                font-size: 1.4rem;
            }
        }
    }
    &-row{
        display: flex;
        flex-direction: column;

    }
    &-col{
        padding: 4px 5px;

    }
    &-buttons{
        width: 300px;
        display: flex;
        flex-direction: row-reverse;
        padding: 0px 10px;
    }

    &-form{
        padding: 10px;
        margin:10px 0;

        .btn-submit{
            width: 150px;
        }
    }
    &-heading{
        padding: 10px;
        background: #333;
        color:#fff;
        font-weight: 400;
        font-size: 1.6rem;
    }
}