@import '../../scss/abstracts/variables';

.user{
    &-details{
        width: 100%;
        display: grid;
        grid-template-columns: 170px calc(100% - 170px);
        padding: 15px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12);
        margin-bottom: 15px;
        background-color: #fff;
        border-radius: 4px;
    }
    &-list{
        display: flex;
        flex-direction: column;
    }
    &-name{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        h2{
            font-size: 22px;
            color: #565656;
            line-height: 1.5;
        }

        span{
            margin-left: 10px;
            font-size: 17px;
            color: #787878;
        }
    }
    &-email{
        font-size: 16px;
        color: #555;
    }
    &-phone{
        font-size: 16px;
        color: #555;
    }
}

.change{
    padding: 15px;
    margin: 15px 0;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.13);
    border-radius: 3px;
}

.access{
    width: 100%;
    padding: 15px 0;
    display: flex;
    flex-direction: column;

    &-headers{
        display: flex;
        flex-direction: row;
        border: 1px solid #efefef;
    }
    &-header{
        font-size: 16px;
        font-weight: 500;
        color:#{$color-blue};
        padding: 10px 15px;
        background-color: #fff;
        // background-color: #dfdfdf;
        border-right: 1px solid #efefef;
        text-transform: capitalize;
        width: 120px;
        text-align: center;

        &.all{
            width: 150px;
        }
    }
    &-body{
        width: 100%;
        display: flex;
        flex-direction: column;
        // border: 1px solid #fff;
        border-top: unset;

        &__item{
            display: flex;
            flex-direction: row;
            
        }
    }
    &-button{
        width: calc(120px * 6 + 150px);
        padding: 15px 0;
    }
    &-content{
        display: flex;
        flex-direction: row;
        border-right: 1px solid #efefef;
        border-left: 1px solid #efefef;
        &-heading{
            width: 120px;
            text-align: center;
            line-height: 2;
            background-color: #fff;
            border-bottom: 1px solid #efefef;
            border-right: 1px solid #efefef;
            padding: 10px 20px;
            h3{
                font-size: 15px;
                font-weight: 500;
                color:#333;
                text-transform: capitalize;
            }
        }
        &-value{
            width: 120px;
            text-align: center;
            line-height: 2;
            background-color: #fafafa;
            border-bottom: 1px solid #efefef;
            border-right: 1px solid #efefef;
            cursor: pointer;
            padding: 10px;
            font-size: 16px;
            color:#333;
            text-transform: capitalize;
            &:hover{
                background-color: #e1e1e1;
            }
            .rs-icon{
                font-size: 17px;
            }
            .rs-icon-close{
                color: #{$color-4};
            }
            .rs-icon-check{
                color: #93c054;
            }

            &.all{
                width: 150px;
                display: flex;
                flex-direction: row;
                padding: 0;
                &:hover{
                    background-color: #fff;
                }

                span{
                    width: calc(50%);
                    // background-color: #{$color-5};
                    background-color: #fff;
                    color:#333;
                    padding: 12px 10px;
                    // padding: 15px;
                    &:hover{
                        background-color: #f5f5f5;
                    }
                    &:nth-child(2){
                        // background-color: lighten($color-red,10);
                        border-left: 1px solid #f1f1f1;
                        color:#fff;
                    }
                }
            }
        }
    }
}