.modal{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, .8);
    z-index: 9999;
    // opacity: 0;
    // visibility: hidden;
    transition: all .3s;
    cursor: auto;
    // @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
    //     -webkit-backdrop-filter: blur(10px);
    //     backdrop-filter: blur(10px);
    //     background-color: rgba(#000, .3);
    // }

    &.active{
        opacity: 1;
        visibility: visible;
        .modal-content {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
    &__header{
        width: 100%;
        padding: 15px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #cfcfcf;

        &-close{
            &,
            &:link,
            &:visited {
                color: #525252;
                font-size: 2.5rem;
                cursor: pointer;
                text-decoration: none;
                display: inline-block;
                transition: all .2s;
                line-height: 1;
            }
    
            &:hover {
                color: #{$color-red};
            }
        }
        
    }
    &__heading{
        h2{
            font-size: 2rem;
            color: #444;
            text-transform: capitalize;
            font-weight: 400;
        }

    }
    &-message{
        background: #ffffff;
        padding: 0;

        .modal-icon{
            font-size: 7rem;
            text-align: center;
            margin: 0;
            padding: 0;
        }
        h2{
            font-size: 3rem !important;
            text-align: center;
        }
        .modal-footer{
            padding: 5px 10px;
            margin-bottom: 2rem;
            justify-content: center !important;
        }
    }
    &.success{
        .modal-icon,h2{
            color: #7ab96e;
        }
        .modal-footer{
            a{
                background-color: #7ab96e !important;
                width: 40%;
                text-align: center;
                font-size: 2rem;
            }
        }
    }
    &.error{
        .modal-icon,h2{
            color: #f44336;
        }
        .modal-footer{
            a{
                background-color: #f44336 !important;
                width: 40%;
                text-align: center;
                font-size: 2rem;
            }
        }
    }

    &-content{
        @include absCenter;
        // height: 20rem;
        min-height: 20rem;
        max-height: 90vh;
        max-width:90vw;
        width: 60%;
        background-color: $color-white;
        box-shadow: 0 2rem 4rem rgba($color-black, .2);
        border-radius: 3px;
        display: block;
        overflow: auto;
        // opacity: 0;
        opacity: 1;
        visibility: visible;
        transform: translate(-50%, -50%) scale(1);
        // transform: translate(-50%, -50%) scale(.25);
        transition: all .25s;
    }
    &__confirm{
        @include absCenter;
        height: 25rem;
        width: 40rem;
        background-color: #fff;
        box-shadow: 0 2rem 4rem rgba(26, 26, 26, 0.2);
        border-radius: 3px;
        display: table;
        padding: 1rem;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    &-main{
        padding: 1rem 1.5rem;
        flex-grow: 1;

    }
    &-delete{
        min-height: 7.5rem;
        h2{
            font-size: 2rem !important;
            color:#868686 !important;
        }
    }
    &-footer{
        // padding: 1rem 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}




.modalMessage{
    &.warning{
        .modalMessage-icon{
            color:#{$color-yellow};
        }
        .modalMessage-buttons{
            &__confirm{
                background-color: #{$color-yellow};
            }
        }
    }
    &.error{
        .modalMessage-icon{
            color:#{$color-red};
        }
        .modalMessage-buttons{
            &__cancel{
                background-color: #{$color-red};
                color:#fff;
            }
        }
    }
    &.success{
        .modalMessage-icon{
            color:#{$color-green};
        }
        .modalMessage-buttons{
            &__cancel{
                background-color: #{$color-green};
                color:#fff;
            }
        }
    }

    &-icon{
        color:#{$color-yellow};
        font-size: 6.5rem;
        display: flex;
        justify-content: center;
    }
    &-title{
        color:#646464;
        font-size: 3rem;
        margin:1rem 0;
    }
    p{
        margin:.75rem 0;
        color:#333;
        font-size: 1.5rem;
        flex-grow: 1;
        font-weight: 500;
        span{
            display: block;
        }
        .error{
            color:#{$color-red};
            margin-bottom: .5rem;
        }
    }
    &-buttons{
        display: flex;
        // justify-content: space-evenly;
        justify-content: center;


        &__confirm{
            color:#fff;
            background-color: #{$color-primary};
            text-decoration: none;
            text-transform: capitalize;
            font-size: 1.5rem;
            padding: .75rem 1.5rem;
            border-radius: 4px;
            margin-right: 1rem;
            transition: all .2s;
            cursor: pointer;

            &:hover{
                background-color:lighten($color-primary,7);
                transform:translateY(-3px);
                box-shadow: 0 4px 7px rgba(0,0,0,0.2);
            }
            &:active{
                transform:translateY(-1px);
                box-shadow: 0 2px 3px rgba(0,0,0,0.12);
            }
        }
        &__cancel{
            color:#555;
            background-color: #e6e6e6;
            text-decoration: none;
            text-transform: capitalize;
            font-size: 1.5rem;
            padding: .75rem 1.5rem;
            border-radius: 4px;
            transition: all .2s;
            cursor: pointer;
            &:hover{
                background-color:lighten(#e6e6e6,4);
                transform:translateY(-3px);
                box-shadow: 0 4px 7px rgba(0,0,0,0.2);
            }
            &:active{
                transform:translateY(-1px);
                box-shadow: 0 2px 3px rgba(0,0,0,0.12);
            }
        }
    }
}


.con{
    position: relative;
  z-index: 0;
}
