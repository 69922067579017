.toast{
    position: fixed;
    padding: 1rem 2rem;
    border-radius: 10rem;
    background: #7a7a7a;
    text-align: center;
    font-size: 1.75rem;
    z-index: 11111111111111;
    box-shadow: 0 3px 6px rgba(0,0,0,0.2);

    &-body{
        width: 100%;
    }

    &.with-list{
        border-radius: 4px;
    }
    &.error{
        background: rgba($color-red,.85);
        color: #fff;
    }
    &.success{
        background: rgba($color-green,.85);
        color:#fafafa;
    }
    &.info{
        background: rgba($color-primary-light,.85);
        color:#fafafa;
    }
    &.warning{
        background: rgba($color-yellow,.85);
        color:#fafafa;
    }
    &.bottom-right{
        bottom: 3rem;
        right: 3rem;
    }
    &.top-right{
        top: 3rem;
        right: 3rem;
    }
    &.bottom-left{
        bottom: 3rem;
        left: 3rem;
    }
    &.top-right{
        top: 3rem;
        right: 3rem;
    }

    &-list{
        text-align: left;
        padding: 0 1.5rem;
        list-style: square outside none;
    }

}