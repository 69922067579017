.panel{
    width: 100%;
    margin:1rem;
    &-title{
        font-size: 1.75rem;
        background: #{$color-primary-dark};
        color:#fff;
        padding:10px;
        font-weight: 500;
    }
    &-body{
        padding:10px;
    }
}

.table{
    width: 60%;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    border-spacing: 0px;
    thead{
        th{
            padding:8px 10px;
            font-weight: 500;
            font-size: 1.5rem;
            color:#fff;
            background: #{$color-green};
        }
    }
    tbody{
        tr{
            &:nth-child(even){
                background: #f1f1f1;
            }
        }
        
        td{
            padding:5px 10px;
            font-size: 1.4rem;
            font-weight: 500;
            text-align: center;
            border-bottom: 1px solid #e6e6e6;
            img{
                width: 60px;
                height: 60px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }
}


.chips{
    padding: 4px 14px;
    font-size: 14px;
    background: #fff;
    color:#555;
    display: inline-block;
    border-radius: 12rem;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    
    &.not-rounded{
        border-radius: 3px;    
    }
    &:hover{
        background: #fafafa;
    }

    &-red{
        background: #{$color-4};
        color:#fff;

        &:hover{
            background: darken($color-4,10);
        }
    }
    &-green{
        background: #{$color-blue};
        color:#fff;
        &:hover{
            background: darken($color-blue,10);
        }
    }
    &-color-1{
        background: #{$color-1};
        color:#4e4e4e;

        &:hover{
            background: darken($color-1,10);
        }
    }
    &-color-2{
        background: #{$color-2};
        color:#fff;

        &:hover{
            background: darken($color-2,10);
        }
    }
    &-color-3{
        background: #{$color-3};
        color:#4e4e4e;

        &:hover{
            background: darken($color-3,10);
        }
    }
    &-color-4{
        background: #{$color-4};
        color:#fff;

        &:hover{
            background: darken($color-4,10);
        }
    }
    &-color-5{
        background: #{$color-5};
        color:#fff;

        &:hover{
            background: darken($color-5,10);
        }
    }
}