.forgot{
    &-password{
        background-color: #f0f2f5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        position: relative;
    }
    &-container{
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p{
            color:#646464;
            font-size: 1.6rem;
            font-weight: 400;;
        }
    }
    &-form{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;

       .forgot-email{
            width: 50%;
            padding: 1rem 1.5rem;
            color:#444;
            font-size: 1.5rem;
            border:1px solid #c4c4c4;
            margin:1rem 0;
            border-radius: 3px;

        }
    }
    &-btn{
        background: #{$color-primary};
        color:#fff;
        border-radius: 20px;
        padding: 1rem 1.5rem !important;

    }
    &-back{
        position: absolute;
        top:4rem;
        left:4rem;

        a{
            color:#{$color-grey};
            padding:1rem 1.5rem;
            border-radius: 5px;
            background: #dfdfdf;
            font-size: 1.5rem;
            transition: all .3s;
            cursor: pointer;

            &:hover{
                background: lighten($color-grey,10);
                color: #fff;;
            }
        }
    }
    &-title{
        position: absolute;
        top:4rem;
        left:4rem;

        a{
            color:#{$color-primary-light};
            font-size: 2.5rem;
            font-weight: 500;
            cursor: pointer;
        }
    }
}


.table-cont{
    padding:20px;
    margin-top: 20px;
}