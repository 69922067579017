.reviews{
    padding:20px;
    margin-top: 20px;
}


.rs-modal-wrapper{
    z-index: 12 !important;
    .rs-modal{
        width: 800px !important;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888; 
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
    
}

.review{
    &-modal{
        width: 100%;
        display: flex;
        flex-direction: column;

        &__name{
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            h3{
                font-size: 20px;
                font-weight: 500;
                color: #333;
                margin-right: 6px;
                line-height: 1.5;
            }
            p{
                font-size: 15px;
                color: #b3b3b3;
                line-height: 1.5;
            }
        }
        &__chips{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 15px 0;
            div{
                margin-right: 10px;
            }
        }
        &__details{
            padding: 15px 0;
            h4{
                font-size: 20px;
                font-weight: 400;
                color:#343434;
                text-transform: capitalize;
            }
            p{
                font-size: 14px;
                color: #676767;

            }
        }
        &__image{
            max-width: 100%;

            img{
                width: 100%;
            }
        }

    }
}