@import '../../scss/abstracts/variables';
@import '../../scss/abstracts/mixins';

.settings{
    &-expands{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 24px;
        @include respond(phone){
            grid-template-columns: repeat(1,1fr);
        }
    }
    &-expand{
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid #cfcfcf;
        padding: 12px 16px;
        height: 50px;
        overflow: hidden;
        transition: all .2s;
        &__open{
            height: unset;
            .settings-expand__header svg{
                transform: rotate(0deg) !important;
            }
        }
        

        &__header{
            font-size: 18px;
            margin-bottom: 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            svg{
                transition: all .2s;
                transform: rotate(180deg) !important;
            }
        }
        &__body{
            padding: 12px 0;

        }
        &__list{
            display: flex;
            flex-direction: column;
            gap: 12px;

            a{
                background-color: #F5F5F5;
                &:nth-child(even){
                    background-color: #fff;
                }
            }
        }
        &__item{
            color: #333;
            border-radius: 8px;
            padding: 12px 16px;
            border: 1px solid #DCDDDF;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            
        }
    }
}


.draft-form{
    // width: 400px;

    #body{
        width: 800px !important;
        max-width: 800px;

        .tox{
            width: 100% !important;
            height: 400px !important;
        }
    }
}