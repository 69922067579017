.booking{
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    &-link{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        color: #fff;
        margin-top: 24px;
        a{
            color: #fafafa;
            cursor: pointer;
            font-weight: 600;
            font-size: 15px;
        }
    }
    &-details{
        // background-color: #f6f6f6;
        background-color: rgba(#876097,.75);
        // border-bottom: 2px solid #efefef;
        padding: 20px;
        display: grid;
        grid-template-columns: 350px 1fr;
        grid-gap: 20px;

        &__image{
            height: 250px;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &__extra{
            // padding: 10px;
            display: flex;
            flex-direction: column;
        }

    }
    &-row{
        display: flex;
        flex-direction: row;
        // margin: 10px 0;
        flex-wrap: wrap;
        gap: 12px;
    }
    &-column{
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        padding: 10px 16px;
        // background-color: #fff;
        background-color: rgba(#afd35a,.75);
        // background-color: #afd35a;
        border-radius: 12px;

        label{
            font-size: 13px;
            line-height: 1;
            color: #ffffff;
            // color: #767676;
            font-weight: 500;
            margin-bottom: 2px;
            letter-spacing: .4px;
        }
    }
    &-value{
        font-size: 14px;
        font-weight: 500;
        color:#111;
    }
    &-aditional{
        padding: 16px 20px;
        background-color: #ee4c9b;
        flex: 1;
        h3{
            color: #fff;
            margin-bottom: 16px;
            font-weight: 600;
            font-size: 20px;
        }
        &__container{
            display: flex;
            flex-direction: column;
            gap: 24px;
        }
    }
    &-flex{
        display: flex;
        flex-direction: row;
        // align-items: center;
    }
    &-emergency{
        // margin-top: 15px;
        // padding: 20px;

        &__card{
            padding: 16px 24px;
            // background-color: #fff;
            background-color: #8dd8f8;
            // box-shadow: 0 3px 5px rgba(0,0,0,0.12);
            display: inline-block;
            // border-radius: 4px;
            min-width: 400px;
            color: #333;
        }
        &__title{
            font-size: 18px;
            color:#222;
            font-weight: 600;
            margin-bottom: 8px;
            line-height: 28px;
        }
        &__details{
            display: flex;
            flex-direction: column;
        }
        &__row{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 4px 0;
        }
        &__label{
            min-width: 100px;
            font-size: 14px;
            font-weight: 600;
        }
        &__value{
            font-size: 14px;
        }
    }
}
.additional-card{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 18px;
    &__image{
        width: 120px;
        img{
            width: 100%;
            object-fit: contain;
        }
    }
    &__details{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;

    }
    &__row{
        padding: 6px 12px;
        border-radius: 8px;
        background-color: rgba(#f8ee32,.7);
        color: #222;
    }
    &__label{
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        color: #333;
    }
    &__value{
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        color: #333;
    }
}

.bookings{
    &-table{
        .rs-table-row-expanded{
            // height: unset !important;
        }
    }
}
.book-expand{
    display: flex;
    flex-direction: row;
    gap: 12px;
    &__passport{
        height: 70px;
        img{
            height: 100% !important;
        }
    }


}
.book_expand{
    &__title{
        font-size: 16px;
        font-weight: 600;
        color: #222;
    }
    &__flex{
        display: flex;
        flex-direction: row;
        gap: 16px;
    }
    &__name{
        font-size: 14px;
        font-weight: 600;
        color: #444;
    }
    &__passport{
        font-size: 14px;
        font-weight: 400;
        color: #444;
    }
    &__date{
        font-size: 13px;
        font-weight: 400;
        color: #767676;
    }
}

.cards{
    &-modal{

    }
    &-preview{
        // padding: 24px;
        width: 750px;
        background-color: #fff;
        margin: 16px auto;
        
        &__body{

        }
        &__header{
            background-color: #0FA7C8;
            color: #fff;
            padding: 12px 22px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 17px;
            font-weight: 400;
            line-height: 28px;
        }
        &__content{
            padding: 24px;

        }
        &__qrs{
            // display: flex;
            // align-items: center;

        }
        &__card{
            margin-right: 16px;
            // width: 375px;
            width: 100%;
            height: 235px;
            
            &-image{
                width: 100%;
                height: 100%;
                position: relative;
                
            }
            &-images{
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;
            flex-direction: row;
            gap: 12px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            &-content{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                padding: 24px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: #fff;
            }
            &-by{
                width: max-content;
                text-align: center;
                div{
                    // font-family: $font-poppins;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 27px;
                    letter-spacing: 3.75px;
                }
                span{
                    // font-family: $font-poppins;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 3.75px;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
            &-to{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
                // font-family: $font-poppins;
                font-style: normal;
                font-weight: bold;
                font-size: 17px;
                line-height: 25px;
                letter-spacing: 0.15px;
                color: #FFFFFF;
            }
        }
        &__qr{
            flex-grow: 1;
            display: flex;
            justify-content: center;

            &-image{
                width: 200px;
                height: 200px;
                img{
                    width: 100%;
                }
            }
        }
        &__details{
            padding: 24px 0 4px;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 20px;
        }
        &__detail{
            padding: 12px 0;
            &-flex{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 32px;
                margin-bottom: 24px;
                &:not(:last-child){
                    margin-bottom: 24px;
                }
            }
            &-passport{
                width: 120px;
                height: 120px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            &-header{
                padding-bottom: 12px;
                border-bottom: 1px solid #bdbdbd;
                margin-bottom: 12px;
            }
            &-title{
                font-size: 18px;
                font-weight: bold;
                color:#52575c;
                line-height: 28px;
            }
            &-row{
                display: grid;
                grid-template-columns: repeat(1,1fr);
                // @include respond(tab){
                //     grid-template-columns: repeat(3,1fr);
                // }
            }
            &-col{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 12px 0;
            }
            &-label{
                font-size: 13px;
                font-weight: 400;
                color: #8d8d8d;
                line-height: 18px;
                margin-right: 8px;
            }
            &-value{
                font-size: 13px;
                font-weight: 600;
                color: #2b2828;
                line-height: 18px;
            }
        }
    }
}