.collapsible{
    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    margin: 8px 0 16px;
    list-style: none;

    &-list{
        list-style-type: none;
        &.active{
            .collapsible-body{
                // display: block;
                // transition: all .2s linear;
                animation: slideHeight .5s linear;
            }
        }
        &.unactive{
            .collapsible-body{
                // display: block;
                // transition: all .2s linear;
                animation: slideOutHeight .5s linear;
            }
        }
    }
    &-header{
        display: flex;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        line-height: 1.5;
        padding: 28px;
        background-color: #fff;
        border-bottom: 1px solid #ddd;

        &__circle{
            width: 40px;
            height: 40px;
            line-height: 1.375;
            border-radius: 50%;
            background-color: #fcbc58;
            color: #fafafa;
            text-align: center;
            font-size: 28px;
            position: relative;
        }
        &__text{
            margin-left: 30px;
            line-height: 24px;
            font-size: 16px;
            font-weight: 400;

            b{
                display: block;
                font-size: 18px!important;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }
    &-body{
        font-size: 17px;
        color: #555;
        font-weight: 450;
        text-align: justify;
        justify-content: text;
        line-height: 2;
        overflow: hidden;
        display: none;
        padding: 20px;
        transition: all .2s linear;
    }
}


@keyframes slideHeight{
    0%{
        max-height: 0;
    }
    100%{
        max-height: 70vh;
    }
}

@keyframes slideOutHeight{
    0%{
        max-height: 70vh;
    }
    100%{
        max-height: 0vh;
    }
}