.section{
    &-login{
        width: 100vw;
        height: 100vh;
        position: fixed;
        display: grid;
        grid-template-columns: 10fr 8fr;
        z-index: 1111;
        // grid-auto-columns: 3fr 2fr;
    }

    &-addCustomer{
        width: 90%;
        margin:0 auto;
    }

    &-customer{
        width: 100%;
        // display: grid;
        // grid-template-columns: 60% 40%;
        // grid-template-columns: repeat(2,1fr);
    }
}