.customers{
    width: 100%;
    padding:20px;
    font-family: 'Open Sans',  'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

    .btn-rounded{
        max-height: 12rem;
        font-size: 1.5rem;
        padding:.75rem 2.5rem;
    }
    &-left,&-right{
        padding:20px;
    }
    &-right{
        
    }
    &-details{
        width: calc(100% - 40px);
        background-color:#323232;
        padding:20px;
        margin:20px;
        background-image: url('../../assets/back.png');
        background-repeat: no-repeat;
        background-size: 90%;
        background-position: right;
        height: 300px;
        color:#f3f3f3;

        .details{
            &-row{
                padding: 7px;
            }
            &-title{
                font-size: 15px;
                margin-right: 10px;
                color:#{$color-primary-light-3}
            }
            &-name{
                font-size: 14px;
                color:#fafafa;
            }
        }
    }
}

.customer{
    &-right{
        display: flex;
        flex-direction: row;
    }
}

.user-box{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    width: 300px;
    margin: 30px 30px 0 0;
    // margin: 10px 10px 20px auto;
    background: #fff;
    font-family: 'Roboto', sans-serif;
    font-family: 'Open Sans', sans-serif;
    border-radius: 6px;

    &__heading{
        padding: 10px 12px;
        background-color: #4098D7;
        color: #fafafa;
        font-size: 16px;
        line-height: 14px;
        font-weight: 500;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }
    &__details{
        display: flex;
        flex-direction: row;
        padding: 10px;

        .flex{
            margin: 10px 0;

            .time{
                font-size: 14px;
                margin-right: 10px;
            }
        }
    }
    &__images{
        width: 60px;
        height: 60px;
        margin-right: 25px;
        img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;

        }
    }
    .user-details{
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        h4{
            color: #565656;
            font-size: 16px;
            line-height: 12px;
            
        }
        .button-group{
            padding:0;
            flex-direction: row-reverse;

        }
        .btn{
            // border-radius: 4px;
            // background-color: #C7E8FF;
            background-color: transparent;
            color: #565656;
            // border: 1px solid #797070;
            border-bottom: 1px solid #797070;
            padding: 0 .5rem;
            // padding: 0 1.5rem;
            font-size: 14px;
            width: 75px;
            // width: 100px;
            font-weight: 500;
            height: 3.25rem;
            // transition: all .2s ease-in;

            i,svg{
                margin-left: 8px;
                font-size: 22px;
                transition: all .2s ease-in;
            }

            &:hover{
                background-color: darken(#C7E8FF,6);
                i,svg{
                    margin-left: 16px;
                }
            }
        }
    }
}

.time-tab{
    display: flex;
    flex-direction: row-reverse;

    &-list{
        list-style: none;
        display: flex;
        flex-direction: row;
        border-radius: 12rem;
        box-shadow: 0 2px 4px rgba(0,0,0,0.14);

        &__item{
            padding:8px;
            background: #fff;
            color:#565656;
            cursor: pointer;
            width: 100px;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            &:first-child{
                border-top-left-radius: 12rem;
                border-bottom-left-radius: 12rem;
            }
            &:last-child{
                border-top-right-radius: 12rem;
                border-bottom-right-radius: 12rem;
            }
            &:hover{
                background-color: #f7f7f7;
            }

            &.active-tab{
                color:#fff;
                background-color: #{$color-4};
                
            }
        }
    }
}

.filters{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    &-item{
        max-width: 250px;
        margin:2px 5px;
        input{
            width: 100% !important;
        }
    }
    &-block{
        padding:10px;
        background:#fff;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        margin:0px 0 10px;
        border-radius: 5px;
    }
}

.paper{
    // width: 1123px;
    // height: 794px;
    width: 100%;
    // height: 100%;
    display: flex;
    // justify-content: flex-end;
    align-items: center;
}

.boarding{
    &-container{
        max-width: 775px;
        position: relative;
        // margin-left: 60px;
    }
    &-image{
        // width: 775px;
        // height: 340px;

        width: 650px;
        height: 275px;

        // width: 100%;
        img{
            width: 100%;
        }
    }
    &-wifi{
        position: absolute;
        // top: 155px;
        // left: 38px;
        top: 103px;
        left: -6px;

        display: flex;
        flex-direction: column;
        transform: rotate(-90deg);
        transform-origin: center;

        height: 55px;
        width: 110px;
        justify-content: center;

        span{
            font-size: 13px;
            font-weight: 500;
            color: #eb4b9a;
            line-height: 1.5;
        }
    }
    &-code{
        width: 78px;
        height: 78px;
        position: absolute;
        bottom: 10px;
        right: 28px;

        img{
            width: 100%;
            height: 100%;
        }
    }
    &-details{
        position: absolute;
        width: 450px;
        height: 220px;
        top: 30%;
        left: 25%;


        .position{
            position: absolute;
            left:98px;
            // left:125px;
            font-size: 14px;
            font-weight: 600;
            // color:#444;
            color:#eb4b9a;

            
        }
        .name{
            top: 20px;
            left: 98px;
        }
        .country{
            top: 53px;
        }
        .checked-in{
            top:88px;
        }
        .checked-out{
            top: 88px;
            left: 275px;
        }
        .email{
            top: 121px;
        }
        .roomType{
            top: 154px;
        }
        .status{
            top: 154px;
            left: 260px;
        }
    }
}