.rs-table {
    .header-cell-group {
      .rs-table-cell-content {
        padding: 0;
      }
      &-title,
      &-subtitle span {
        padding: 10px;
        border-bottom: 1px solid #f2f2f5;
      }
      &-subtitle span {
        display: inline-block;
        border-right: 1px solid #f2f2f5;
      }
    }
}
.rs-table-cell-bordered .rs-table-cell {
    border-width: 0 1px 0 0;
}
.rs-table-row-header .rs-table-cell {
    background: #fff;
}
.rs-table-cell {
    height: 36px;
    border: 0 solid #f2f2f5;
    display: block;
    overflow: hidden;
    position: absolute;
    white-space: normal;
    background: #fff;
}
.rs-table-cell-header .rs-table-cell-content {
    padding: 10px;
    font-size: 12px;
    line-height: 1.66666667;
}
.rs-table-cell {
    height: 36px;
    border: 0 solid #f2f2f5;
    display: block;
    overflow: hidden;
    position: absolute;
    white-space: normal;
    background: #fff;
}
// .rs-table-cell-content {
//     padding: 13px 10px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     width: 100%;
//     border-right: 1px solid #f2f2f5;
// }


.trip{
    &-details{
        padding: 5px 15px 10px;
    }
    &-type{
        .type{
            font-size: 14px;
            margin: 0 3px;
            color: #787878;
        }
        .activity{
            font-size: 17px;
            color: #d58888;
            margin-right: 10px;
        }
    }
    &-summary{
        padding: 2px 0 4px;
        font-size: 15px;
        color: #565656;
        // line-height: 1;
        margin-bottom: 4px;
    }
    &-describe{
        font-size: 14px;
        color: #666;
    }
}