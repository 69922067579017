@import '../../../scss/abstracts/variables';

.details{
    padding: 20px;

    &-form{
        display: flex;
        flex-direction: column;

        &__input{
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            padding: 10px;
        }
        &__label{
            font-size: 18px;
            color:#464646;
            font-weight: 500;
            margin-bottom: 6px;
            font-family: $font-roboto;
        }
    }
}


.error-back{
    background: #ffd6d6;
}