.form{
    &-group{
        width: 100%;



    }
    &-control{
        padding:10px 0 ;
        outline:none;
        border:none;
        border-bottom: 1px solid #aeaeae;
        font-size: 1.6rem;
        color:#555;
        margin-bottom: 2rem;;

        &::placeholder{
            color:#b4b4b4;
            font-size: 16px;
            font-weight: 500;
        }
        &:-webkit-autofill{
            // background: transparent;
            -webkit-box-shadow: 0 0 0px 100px #fcfcfc inset;
            transition: background-color 5000s ease-in-out 0s;
        }

        &-lg{
            width: 60%;
        }
    }
}