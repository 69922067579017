.center-text { text-align: center !important; }

.mb-sm { margin-bottom: 1.5rem !important; }
.mt-sm { margin-top: 1rem !important; }
.mb-md { margin-bottom: 2.5rem !important; }
.mt-md { margin-top: 2.5rem !important; }
.mb-md {
    margin-bottom: 4rem !important;

    @include respond(tab-port) {
        margin-bottom: 3rem !important;
    }
}

.mb-bg { 
    margin-bottom: 8rem !important;

    @include respond(tab-port) {
        margin-bottom: 5rem !important;
    }
}

.mt-bg{ margin-top: 8rem !important; }
.mt-hg { margin-top: 10rem !important; }


.pb-sm { margin-bottom: 1.5rem !important; }
.pb-md { margin-bottom: 2.5rem !important; }

.pt-sm { margin-top: 1rem !important; }
.pt-md { margin-top: 2.5rem !important; }

.text-left{text-align: left;}
.text-right{text-align: right;}
.text-center{text-align: center;}

.flex{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.flexCenter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.space-between{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}