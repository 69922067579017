// .header{
//     position: relative;
//     width: 100%;
//     min-height: 5rem;
//     height: 6rem;
//     flex: 0 0 auto;
//     line-height: 6rem;
//     padding: 0 2rem;
//     background-color: #444;
//     -webkit-box-shadow: 0 4px 8px ;
//     box-shadow: 0 2px 4px rgba(0,0,0,0.1);
//     &-menu{
//         position: absolute;
//         z-index: 9999;
//         left: 1.5rem;
//         top: 2.25rem;
//         cursor: pointer;
//         transition: all 0.7s ease-out;
//         &__line{
//             width: 2rem;
//             height: 0.25rem;
//             margin: 0 0 4px 0;
//             background: #fff;
//             transition: all 0.5s ease-out;
//         }

//         &-close{
//             // left: 11rem;
//             transform: rotate(180deg);
//             font-weight: bold;
//             .header-menu__line:nth-child(1){
//                 width: 1.5rem;
//                 // transform: rotate(45deg) translate(5px, 5px);
//                 transform: rotate(45deg) translate(4px, 11px);
//             }
//             .header-menu__line:nth-child(2){
//                 width: 2.5rem;
//                 transform: translate(-3px, 0px);
//                 // opacity: 0;
//             }
//             .header-menu__line:nth-child(3){
//                 width: 1.5rem;
//                 // transform: rotate(-45deg) translate(7px, -6px); 
//                 transform: rotate(-45deg) translate(4px, -11px);
//             }
//         }
//     }
//     &-avatar{
//         width: 5rem;
//         border-radius: 50%;
//         position: relative;
//         &__img{
//             width: 100%;
//             border-radius: 50%;

//         }
//         &__badge{
//             position: absolute;
//             top: 0;
//             right: 0;
            

//         }
//     }
// }


.header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-height: 5rem;
    align-items: center;
    height: 6rem;
    flex: 0 0 auto;
    line-height: 6rem;
    padding: 0 2rem;
    // background:rgba($color-primary-light,.5);
    background: rgba(255, 255, 255, 0.44);
    // background-color: #fff;
    // -webkit-box-shadow: 0 4px 8px ;
    // box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    // box-shadow: 0 1px 4px rgba(0,21,41,.08);

    & > div{
        line-height: 0;
    }
    &-menu{
        
        z-index: 11;
        cursor: pointer;
        transition: all 0.7s ease-out;
        &__line{
            width: 2rem;
            height: 0.2rem;
            margin: 0 0 4px 0;
            background: #646464;
            transition: all 0.5s ease-out;
        }
        &__icon{
            font-size: 30px;
            margin-left: 1rem;
            svg,i{
                // height: 27px !important;
                font-size: 30px !important;
                color:#777;
            }
            
        }
    }
    &-right{
        display: flex;

        &__icon{
            margin-right: 20px;
            color:#777;
            & > i{
                height: 24px;
                font-size: 24px;;
                color:#777;
                padding-bottom: 10px;
            }
        }
    }
    &-search{
        font-size: 2rem;
        width: 4rem;
        display: inline-block;
        color: #646464;
        cursor: pointer;
        transition: all .4s ease;

        input{
            width: 0;
            opacity: 0;
            border: none;
            outline: none;
            border-bottom: 1px solid #efefef;
            font-size: 1.3rem;
            color:#333;
            padding: 0.5rem 1rem;
            transform-origin: left;
            transition: width .3s ease-in;
        }
        ::placeholder{
            text-transform: capitalize;
            font-size: 1.5rem;
            color:#d7d7d7;
        }

        &.active{
            width: 19rem;
            input{
                width: 14rem;
                opacity: 1;
            }
        }
    }
    &-notification{
        display: inline-block;
        width: 4rem;
        color: #646464;
        font-size: 2rem;
        cursor: pointer;
    }
    &-dropdown{
        position: relative;
        padding-top: 10px;
        svg{
            color: #757575;
            font-size: 2rem;
            cursor: pointer;
        }

        &.active{
            .header-dropdown__content{
                opacity: 1;
                visibility: visible;
            }
            
        }

        &__content{
            position: absolute;
            width: 15rem;
            opacity: 0;
            visibility: hidden;
            top: 40px;
            right: 100%;
            background: #fff;
            border: 1px solid #e3e3e3;
            z-index: 2;
            box-shadow: 0 4px 8px rgba(0,0,0,0.2);
            z-index: 100;
            border-radius: 4px;
            transition: 0.2s all ease-in-out;

            &:after{
                content:'';
                box-sizing: border-box;
                position: absolute;
                height: 15px;
                width: 15px;
                z-index: -1;
                background-color: #fff;
                border: 1px solid #e5eaed;
                border-bottom: 1px solid transparent;
                // border-left: 1px solid transparent;
                border-right: 1px solid transparent;
                transform: rotate(45deg);
                top: -8px;
                left: 75%;
            }
            .dropdown{
                &-list{
                    list-style: none;
                    width: 100%;
                    margin:0;
                    &__item{
                        width: 100%;
                        padding: 1rem;
                        line-height: 1.5rem;
                        cursor: pointer;
                        display: flex;
                        z-index: 11;
                        // align-items: center;

                        a{
                            display: flex;
                            align-items: center;;
                        }

                        &.with-hr{
                            border-top: 1px solid #efefef;
                        }
                        &:hover{
                            background-color: lighten($color-primary-light-3,10);
                        }

                    }
                    
                }
                &-icon{
                    padding-right: 1rem;
                    svg,i{
                        font-size: 1.5rem;
                        color:#656565;
                    }
                    
                }
                &-name{
                    font-size: 1.3rem;
                    color: #646464;
                }
            }
        }

    }
    &-avatar{
        width: 5rem;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2rem;

        .avatar-image{
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            margin-right: 1.5rem;

            img{
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
            }
            
        }
        &__img{
            width: 100%;
            border-radius: 50%;

        }
        &__badge{
            position: absolute;
            top: 0;
            right: 0;
            

        }
    }
}
