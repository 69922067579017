// .sidebar{
//     flex: 0 0 25rem;
//     max-width: 25rem;
//     min-width: 25rem;
//     width: 25rem;
//     z-index: 9999;
//     // min-height: 100vh;
//     // height: 100vh;
//     box-shadow: 2px 0 6px #001529;
//     transition: all .3s;
//     position: relative;
//     background: #{$color-primary-dark-3};
//     padding: 1rem 0rem;
//     &-logo{
//         display: flex;
//         align-items: center;
//         // justify-content: space-evenly;
//         padding: 1rem 2rem;
//         &__img{
//             width: 40px;
//             height: 40px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             border-radius: 50%;
//             background: #fff;
//             padding: 10px;
//             img{
//                 width: 25px;
//             }
//         }
//         &__heading{
//             display: block;
//             font-size: 2.25rem;
//             color: #fff;
//             margin-left: 1rem;
//             // margin-top: 1rem;
//         }
//     }
//     &-nav{
//         padding: 0 2rem;
//         padding-right: 3rem;
//         font-size:15px;
//         color: hsla(0,0%,100%,.65);
//         display: flex;
//         justify-content: space-between;
//         height: 55px;
//         line-height: 55px;
//         position: relative;

//         &__name{
//             display: flex;
//             align-items: center;

//             h5{
//                 font-weight: normal;
//                 font-size: 14px;
//                 display: block;
//             }

//         }
//         &__icon{
//             transition: all .3s;
//             .iconify{
//                 font-size: 1.75rem;
//                 margin-right: 0.75rem;
//                 color: #efefef;
//             }
//         }
//         .icon-right{
//             position: absolute;
//             right: 10px;
//             opacity:1;
//             visibility: visible;
//             svg{
//                 font-size: 1rem !important;
//             }
//         }
//     }
//     &-subLink{
//         width: 100%;
//         // margin: 1rem 0;
//         background-color: #000c17;
//         display: none;

//         &__item{
//             // padding: 1rem 1.5rem;
//             padding-left: 5rem;
//             font-size:13px;
            
//             display: flex;
//             align-items: center;
//             // justify-content: space-between;
//             height: 45px;
//             line-height: 45px;
//             color: hsla(0,0%,100%,.65);
//             svg{
//                 font-size: 1.75rem;
//                 margin-right: 10px;
//             }

//             a{
//                 width: 100%;
//                 color: hsla(0,0%,100%,.65);
//                 text-decoration: unset;
//                 display: flex;
//                 align-items: center;

//                 svg{
//                     font-size: 1.8rem;
//                     margin-right: 10px;
//                 }
//             }
            
//             &:hover{
//                 background: #061a2d;
//                 a{
//                     color:#fff;
//                 }
//             }
//             &.active{
//                 background: #{$color-blue};
//                 color:#fafafa;
//                 a{
//                     color:#fafafa;
//                 }
                
//             }
//         }
//     }
//     &-link{
//         list-style: none;
//         margin-top: 2rem;
//         width: 100%;

//         &__item{
//             display: block;
//             cursor: pointer;
//             position: relative;
//             transition: all .3s ease;
            
//             &.collapse{
//                 .sidebar-subLink{
//                     display: none;
//                 }
//             }
//             &.expand{
//                 .sidebar-subLink{
//                     display: block;
//                 }
//                 .icon-right{
//                     transform: rotate(180deg) translateX(2px);
//                 }
//             }

//             // span{
//             //     display: none;
//             //     vertical-align: text-bottom;
//             // }

//             i,svg{
//                 display: inline-block;
//                 font-size: 2.25rem;
//             }

//             &:hover{
//                 color: #{$color-primary-light-3};

//                 // span{
//                 //     display: block;
//                 //     position: absolute;
//                 //     left:115%;
//                 //     background-color: rgba(#333,0.5);
//                 //     top: 10%;
//                 //     padding: 0.5rem 1rem;
//                 //     border-radius: 0.25rem;

//                 //     &:before{
//                 //         content:'';
//                 //         position: absolute;
//                 //         left: -21%;
//                 //         top:5%;
//                 //         width: 0; 
//                 //         height: 0; 
//                 //         border-top: 1.25rem solid transparent;
//                 //         border-bottom: 1.25rem solid transparent;
//                 //         border-right: 1.25rem solid rgba(#333,0.5);
//                 //     }
//                 // }
//             }

            
//         }
//         .active{
            
            
//             .sidebar-nav{
//                 background: #{$color-blue};
//                 color:#fff;
//             }
//         }
//     }

//     &-close{
//         flex: 0 0 10rem;
//         max-width: 10rem;
//         min-width: 10rem;
//         width: 10rem;

//         .sidebar-logo{
//             // img{
//             //     width: 60%;
//             //     margin:0 auto;

//             // }
//             &__heading{
//                 display: none;
//             }
//         }
//         .sidebar-link{
//             &__item{
//                 text-align: left;
//                 position: relative;
//                 .sidebar{
//                     &-nav{
//                         justify-content: center;
//                         &__name{
//                             h5{
//                                 display: none;
//                             }
//                         }
//                         &__icon{
//                             svg{
//                                 font-size: 2.25rem;
//                             }
//                         }
//                     }
                    
//                 }
//                 .icon-right{
//                     opacity:0;
//                     visibility: hidden;
//                 }
//                 &:hover,&.expand{

//                     .sidebar-subLink{
//                         display: block !important;
//                         position: absolute;
//                         top: 0;
//                         left: 100%;
//                         background-color: #333;
//                         color:#fff;
//                         list-style: none;
//                         box-shadow: 0 4px 6px rgba(0,0,0,0.2);
//                         min-width: 25rem;
//                         border-radius: 4px;
//                         z-index: 11111;

//                         &__item{
//                             padding-left: 0;
//                             padding: 1rem;

//                             &.active{
//                                 background-color: #646464;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }

    
// }


.sidebar{
    flex: 0 0 22rem;
    max-width: 22rem;
    min-width: 22rem;
    width: 22rem;
    z-index: 11;
    // min-height: 100vh;
    // height: 100vh;
    // box-shadow: 2px 0 6px #001529;
    transition: all .3s;
    position: relative;
    background: #0F5858;
    padding: 1rem 0rem;

    &-header{
        font-size: 2rem;
        text-align: center;
        color:#{$color-primary-light};
        font-weight: 400;
        margin-bottom: 2rem;
        padding: 5px;

        &-image{
            width: calc(100% - 10px);

            .bg-logo{
                max-width: 100%;
            }
            .sm-logo{
                opacity:0;
                width: 0px;
            }
        }
    }
    &-nav{
        width: 100%;
    }
    &-close{
                flex: 0 0 8rem;
                max-width: 8rem;
                min-width: 8rem;
                width: 8rem;
                .sm-logo{
                    display: block;
                }

                .rs-sidenav{
                    margin:0 auto;
                }

                .sidebar-header{
                    // visibility: hidden;
                    // opacity: 0;

                    .bg-logo{
                        opacity:0;
                        width: 0;
                        height: 0;
                    }
                    .sm-logo{
                        opacity:1;
                        width: 30px;
                        margin: 0 auto 10px;
                    }
                }
    }

}
