.nav{
    width: 100%;
    &-list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        

        &__item{
            width: 100%;
            background-color: transparent;
            padding:4px 0;
            color:#fff;

            &-name{
                display: flex;
                align-items: center;
                flex-direction: row;
            }

            .nav-list__link{
                margin-right: 20px;
                display: flex;
                color: #fff;
                font-size: 14px;
                font-weight: 400;
                position: relative;
                padding: 10px 0;
                padding-left: 25px;
                text-decoration: none;
                cursor: pointer;
                &:hover,&:active{
                    text-decoration: none;
                    color:#24aeae;
                }

                &.active{
                    background-color: #24AEAE;
                    border-top-right-radius: 10rem;
                    border-bottom-right-radius: 10rem;

                    &:hover{
                        color:#0f5858;
                    }
                }

                .down-icon{
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    transform: translateY(-50%);
                    font-size: 20px;
                    line-height: 0;
                    &.active{
                        transform: rotate(180deg);
                    }
                }

                .icon{
                    font-size: 22px;
                    margin-right: 15px;
                    line-height: 0;
                }
            }

            &.collapse{
                display: block;
                .sidebar-subLink{
                    display: none;
                }
            }
            &.expand{
                .sidebar-subLink{
                    display: block;
                }
                .icon-right{
                    transform: rotate(180deg) translateX(2px);
                }
            }
        }
    }
}

.sidebar{
    &-subLink{
        width: 100%;
        // margin: 1rem 0;
        background-color: #5F8D8D;
        display: none;

        &__item{
            // padding: 1rem 1.5rem;
            padding-left: 5rem;
            font-size:13px;
            
            display: flex;
            align-items: center;
            // justify-content: space-between;
            height: 45px;
            line-height: 45px;
            color: hsla(0,0%,100%,.65);
            text-decoration: none !important;
            &,&:active{
                text-decoration: none;
            }
            
            &:hover{
                color: #fff;
                text-decoration: none !important;
            }
            svg{
                font-size: 1.75rem;
                margin-right: 10px;
            }

            a{
                width: 100%;
                color: hsla(0,0%,100%,.65);
                text-decoration: unset;
                display: flex;
                align-items: center;

                svg{
                    font-size: 1.8rem;
                    margin-right: 10px;
                }
            }
            
            &:hover{
                background: #5F8D8D;
                a{
                    color:#fff;
                }
            }
            &.active{
                background: #72aec7;
                color:#fafafa;
                a{
                    color:#fafafa;
                }
                
            }
        }
    }

    &-close{
        
        .nav-list{
            &__link{
                &.active{
                    background-color: transparent;
                    color:#5bffff;

                    &:hover{
                        color: #dedede;
                    }
                }
            }
            &__item{
                text-align: left;
                position: relative;
                &-name{
                    .name{
                        display: none;
                    }
                    .icon{
                        svg{
                            font-size: 25px;
                        }
                    }
                }
                .down-icon{
                    opacity:0;
                    visibility: hidden;
                }
                &:hover,&.expand{

                    .sidebar-subLink{
                        display: block !important;
                        position: absolute;
                        top: 0;
                        left: 100%;
                        background-color: #333;
                        color:#fff;
                        list-style: none;
                        box-shadow: 0 4px 6px rgba(0,0,0,0.2);
                        min-width: 25rem;
                        border-radius: 4px;
                        z-index: 11111;

                        &__item{
                            padding-left: 0;
                            padding: 1rem;

                            &.active{
                                background-color: #646464;
                            }
                        }
                    }
                }
            }
        }
    }
}


a{
    &:hover,&:active,&:focus{
        text-decoration: none;
        color:#fff;
    }
}