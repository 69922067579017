.data{
    &-block{
        width: 100%;
        display: block;
        background: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        padding:20px;
    }
    &-table{
        border-spacing: 0px;
        border:1px solid #dedede;
        border-radius: 6px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        // padding:1px;
        thead{
            th{
                padding: 8px 10px;
                font-weight: 500;
                font-size: 1.3rem;
                color: #929294;
                background: #f9f9fb;
                &:first-child{
                    border-top-left-radius: 4px;
                }
                &:last-child{
                    border-top-right-radius: 4px;
                }
            }
        }
        tbody{
            tr{
                background: #fff;
                &:nth-child(even){
                    background: #f7f7f7;
                }
            }
            td{
                padding:8px 10px;
                font-size: 1.3rem;
                font-weight: 500;
                color:#444;
                border-bottom:1px solid #e3e3e3;

                input,select{
                    border: 1px solid rgb(200, 200, 200);
                    border-radius: 12rem;
                    padding: 4px 10px;
                    font-size: 1.2rem;
                    &:focus{
                        outline: none;
                    }
                }
                select option{
                    padding: 4px;;
                }

                img.avatar{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }
                .table-icon{
                    font-size: 2.25rem;
                    width: 100%;
                    text-align: center;
                    display: block;
                }
                &.pagination-row{
                    background: #ffffff;
                    padding: 4px 10px;
                }
            }
        }
    }
}